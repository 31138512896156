const allClasses = [
  {
    id: "a",
    category: 'individual',
    name: 'Discovering and Dismantling Biases In The Creative Industry',
    info: '<p>You can’t change what you don’t know. Once you recognize a bias, how do you dismantle that bias and fix your behavior? In this class we will walk through the different types of biases and their biological importance and pinpoint how they show up in the creative industry.</p>',
    selected: false,
  },
  {
    id: "b",
    category: 'individual',
    name: 'Women, Let’s Check In',
    info: '<p>How are you doing? Do you see a clear path for you at your company? What about “work-life balance”? Do you feel like your company has a boy’s club? Let’s gather just the women for this talk to ensure a safe space for discussion.</p>',
    selected: false,
  },
  {
    id: "c",
    category: 'individual',
    name: '<span class="has-tooltip-arrow has-tooltip-right" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span>, Let’s Check In',
    info: '<p>How are you doing? Do you see a clear path for you at your company? Do you feel like you’re able to show up authentically? Does your company value your opinions and experiences? Let’s gather just the <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span> employees for this talk to ensure a safe space for discussion.</p>',
    selected: false,
  },
  {
    id: "d",
    category: 'individual',
    name: 'So, You Want To Be An Ally? (Gender)',
    info: '<p>What is the role of men in a male-dominated industry? What’s the difference between equity and equality? The goal is to leave this meeting with a clear understanding of the ongoing work we must do individually and as an organization to be effective allies to women and gender-diverse employees.</p>',
    selected: false,
  },
  {
    id: "e",
    category: 'individual',
    name: 'So, You Want To Be An Ally? (Race)',
    info: '<p>What is the role of white individuals in a primarily white industry? What is the difference between intent vs. impact? What are micro and macroaggressions? The goal is to leave this meeting with a clear understanding of the ongoing work we must do individually and as an organization in order to be effective allies to <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span>.</p>',
    selected: false,
  },
  {
    id: "f",
    category: 'individual',
    name: 'How To Start An ERG Dedicated to DEI',
    info: '<p>How do you start an Employee Resource Group (ERG) dedicated to diversity, equity and inclusion (DEI) work within your company? We’ll discuss questions like: “Who should lead the ERG?” “How often should our ERG meet?” “How structured should those meetings be?”</p>',
    selected: false,
  },
  {
    id: "g",
    category: 'individual',
    name: 'How To Maintain DEI Momentum',
    info: '<p>Your company already has a dedicated group to discussing and implementing DEI topics. But, this work isn’t easy and employees can experience burnout quickly. In this class, we will look at the common issues DEI ERGs experience and dive deep into what your ERG needs to be successful and maintain momentum.</p>',
    selected: false,
  },

  {
    id: "f",
    category: 'monthly',
    name: 'Track 1: The DEI Employee Resource Group',
    description: 'How do you start an Employee Resource Group (ERG) dedicated to diversity, equity and inclusion (DEI) within your company? Once you start it, how do you maintain it for years to come?',
    tracks: '<p>How To Start An ERG Dedicated to DEI</p> <p>Leadership’s Role in Diversity, Equity and Inclusion</p> <p>How To Maintain DEI Momentum</p> <p>DEI Committee, Let’s Check In</p>',
    info: '<p><span style="font-weight:bold">How To Start An ERG Dedicated to DEI:</span> How do you start an Employee Resource Group (ERG) dedicated to diversity, equity and inclusion (DEI) work within your company? We’ll discuss questions like: “Who should lead the ERG?” “How often should our ERG meet?” “How structured should those meetings be?”</p> <p><span style="font-weight:bold">Leadership’s Role in Diversity, Equity and Inclusion:</span> Define your role and the role of your employees in DEI work, create short-term and long-term goals specifically around DEI and support your employees at every level to do this work.</p> <p><span style="font-weight:bold">How To Maintain DEI Momentum:</span> Your company already has a dedicated group to discussing and implementing DEI topics. But, this work isn’t easy and employees can experience burnout quickly. In this class, we will look at the common issues DEI ERGs experience and dive deep into what your ERG needs to be successful and maintain momentum.</p> <p><span style="font-weight:bold">DEI Committee, Let’s Check In.</span> How are you doing? Have you been able to turn DEI discussions into measurable, company-wide action? What are your current road blocks? Let’s gather just the DEI Committee for this talk to ensure a safe space for discussion.</p>',
    selected: false,
  },
  {
    id: "g",
    category: 'monthly',
    name: 'Track 2: Empowering Women',
    description: 'Do women enjoy working here? Do they see a clear career path for themselves? How do we make sure the women at our company feel heard, validated and valued?',
    tracks: '<p>Women, Let’s Check In</p> <p>Men, Let’s Check In</p> <p>So, You Want To Be An Ally? (Gender)</p> <p>Putting Words Into Action (Gender)</p>',
    info: '<p><span style="font-weight:bold">Women, Let’s Check In:</span> How are you doing? Do you see a clear path for you at your company? What about “work-life balance”? Do you feel like your company has a boy’s club? Let’s gather just the women for this talk to ensure a safe space for discussion.</p> <p><span style="font-weight:bold">Men, Let’s Check In:</span> How are you doing? Do you feel comfortable talking about gender diversity, equity and inclusion within your company? What about mentoring and empowering those you work with? Let’s gather just the men for this talk to ensure a safe space for discussion.</p> <p><span style="font-weight:bold">So, You Want To Be An Ally? (Gender):</span> What is the role of men in a male-dominated industry? What’s the difference between equity and equality? The goal is to leave this meeting with a clear understanding of the ongoing work we must do individually and as an organization to be effective allies to women and gender-diverse employees.</p> <p><span style="font-weight:bold">Putting Words Into Action (Gender):</span> How do we move past one-time conversations and start achieving real, meaningful change? Let’s utilize survey results and in-person discussion to analyze where your company is starting, where you need to focus, and brainstorm gender-focused diversity, equity and inclusion goals and metrics.</p>',
    selected: false,
  },
  {
    id: "h",
    category: 'monthly',
    name: 'Track 3: Centering <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span> Voices',
    description: 'Do <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span> employees feel like their work, opinions and experiences matter at our company? Are they able to show up to work authentically? How do we acknowledge <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span> experiences without tokenizing?',
    tracks: '<p>Discovering and Dismantling Biases In The Creative Industry</p> <p><span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span>, Let’s Check In</p> <p>So, You Want To Be An Ally? (Race)</p> <p>Putting Words Into Action (Race)</p>',
    info: '<p><span style="font-weight:bold">Discovering and Dismantling Biases In The Creative Industry:</span> You can’t change what you don’t know. Once you recognize a bias, how do you dismantle that bias and fix your behavior? In this class we will walk through the different types of biases and their biological importance and pinpoint how they show up in the creative industry.</p> <p><span style="font-weight:bold"><span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span>, Let’s Check In:</span> How are you doing? Do you see a clear path for you at your company? Do you feel like you’re able to show up authentically? Does your company value your opinions and experiences? Let’s gather just the <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span> employees for this talk to ensure a safe space for discussion.</p> <p><span style="font-weight:bold">So, You Want To Be An Ally? (Race):</span> What is the role of white individuals in a primarily white industry? What is the difference between intent vs. impact? What are micro and macroaggressions? The goal is to leave this meeting with a clear understanding of the ongoing work we must do individually and as an organization in order to be effective allies to <span class="has-tooltip-arrow" data-tooltip="Black, Indigenous, People Of Color">BIPOC</span>.</p> <p><span style="font-weight:bold">Putting Words Into Action (Race):</span> How do we move past one-time conversations and start achieving real, meaningful change? Let’s utilize survey results and in-person discussion to analyze where your company is starting, where you need to focus, and brainstorm race-focused diversity, equity and inclusion goals and metrics.</p>',
    selected: false,
  },
  {
    id: "i",
    category: 'coaching',
    name: 'Diversity Coaching',
    info: '<p>-Free 30-minute Zoom Introduction Call <br/>-$200 60-minute Zoom coaching session<br/>-$100 30-minute Zoom coaching session<br/><span class="tag is-warning">BEST VALUE:</span> $2,000 for 12 monthly, 60-minute Zoom sessions</p>',
    selected: false,
  },
  {
    id: "j",
    category: 'consulting',
    name: 'Diversity Consulting',
    info: '<p>-Free 30-minute Zoom Introduction Call <br/>-$200 60-minute Zoom consulting session<br/>-$100 30-minute Zoom consulting session<br/><span class="tag is-warning">BEST VALUE:</span> $2,000 for 12 monthly, 60-minute Zoom sessions</p>',
    selected: false,
  },
  {
    id: "k",
    category: 'intro',
    name: 'Babes Helpin’ Babes Introduction',
    info: '<p>FREE 30-minute intro call. We want to get to know you and your company.</p>',
    selected: false,
  },
];
export default allClasses;