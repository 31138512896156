import React, { useState, useContext } from 'react';
import { Link } from 'gatsby'
import ItemsContext from '../context/items-context';


const Modal = ({ show, setShow, current, currentinfo }) => {

  const { items, itemsDispatch } = useContext(ItemsContext);
  const [remove, setRemove] = useState(false);
  const handleRemove = (name) => {
    setRemove(true);
    itemsDispatch({ type: 'REMOVE_ITEM', name });
  };
  const closeModal = () => {
    setRemove(false);
    setShow(false);
  }

  return (
    <div id="modal" className={`${show === true ? 'is-active' : ''} modal`}>
      <div className="modal-background"></div>
      <div className="modal-content has-background-white has-box-shadow p-6">
        <h3 className="is-size-3">{remove === true ? 'Selection removed' : 'You’ve selected'}</h3>
        {remove === false ?
          <>
            <h6 dangerouslySetInnerHTML={{ __html: current }} />
            <div className="is-size-6 is-italic mt-2" dangerouslySetInnerHTML={{ __html: currentinfo }} />
            {/* <button
              value={current}
              className="button link mt-1 mb-5"
              onClick={() => handleRemove(current)}
            >
              UNSELECT
            </button> */}
            <p className="pt-2">&nbsp;</p>
            <p className="smallest has-text-weight-bold is-italic my-5">{items.length <= 0 ? 'To give us a better idea of what you’re looking for, select BHB offerings your company could benefit from.' : 'This is not a purchase. Your selections simply let our team know what programming you’re interested in when submitting your contact form.'}</p>
          </>
          : null}
        <div className="columns">
          <div className="column is-half">
            <Link to="/contact/#classAnchor" className="btn" style={{ width: '100%' }}>{items.length <= 0 ? 'CONTACT US' : 'SUBMIT'}</Link>
          </div>
          <div className="column is-half">
            <button className="btn" style={{ width: '100%' }} aria-label="close" onClick={() => closeModal()}>KEEP BROWSING</button>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => closeModal()}></button>
      </div>
    </div>
  )
};
export { Modal as default };