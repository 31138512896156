import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Scrollspy from 'react-scrollspy'

const FeatureGrid = ({ gridItems }) => (
  <>
    <Scrollspy items={['section-1', 'section-2', 'section-3']} currentClassName="is-current" className="columns is-desktop">
      {gridItems.map((item, i) => (

        <li key={item.text} className="column feature-columns is-full-mobile is-one-third-desktop mb-3">
          <Link to={`#section-${i + 1}`} className="section has-text-left is-flex is-flex-direction-column is-justify-content-space-between" style={{ height: '100%' }}>
            <div>
              <h3 className="sans">{item.headline}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
            <p className="smallest has-text-weight-bold is-italic m-0">{item.caption}</p>
          </Link>
        </li>
      ))}
    </Scrollspy >
  </>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      text: PropTypes.string,
      caption: PropTypes.string,
    })
  ),
}

export default FeatureGrid
