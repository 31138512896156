import React, { useState, useContext } from 'react';
import ItemsContext from '../context/items-context'
import PDF_Coaching from '../../pdfs/BHB_CoachingInfo.pdf'
import PDF_Intro from '../../pdfs/BHB_IntroductionOneSheet.pdf'

const ItemCoaching = ({ classes, setShow, setCurrent, setCurrentInfo }) => {

  const { items, itemsDispatch } = useContext(ItemsContext);
  const [list, setList] = React.useState(classes);

  const handleAdd = (name, info) => {
    // add item to list
    itemsDispatch({ type: 'ADD_ITEM', name, info });
    // selected state
    const newList = list.map((item) => {
      if (item.name === name) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });
    setList(newList);
    // open modal
    setShow(true);
    setCurrent(name);
    setCurrentInfo(info);
  };

  const handleRemove = (name) => {
    // remove item from list
    itemsDispatch({ type: 'REMOVE_ITEM', name });
    // selected state
    const newList = list.map((item) => {
      if (item.name === name) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });
    setList(newList);
    setCurrent(null);
    setCurrentInfo(null);
  };

  return (
    <>
      <h1 className="has-text-warning">Start Your Diversity Coaching Today.</h1>
      <h6 className="mb-0">-Free 30-minute Zoom Introduction Call<br />
        -$200 60-minute Zoom coaching session<br />
      -$100 30-minute Zoom coaching session<br />
        <span class="tag is-warning">BEST VALUE:</span> $2,000 for 12 monthly, 60-minute Zoom sessions</h6>
      <p className="is-italic">(Please note that this investment should be covered by your company. We will not accept payment from individuals.) </p>
      {list.map(item => (
        <>
          {item.selected ?
            <button
              key={item.id}
              value={item.name}
              className="btn--gray my-4 is-italic"
              onClick={() => handleRemove(item.name)}
            >
              SELECTED<span className="tag is-delete ml-2"></span>
            </button> :
            <button
              key={item.id}
              value={item.name}
              className="btn my-4"
              onClick={() => handleAdd(item.name, item.info)}
            >
              SELECT
              </button>
          }
        </>
      ))}
      <h6 className="has-text-warning mt-5">DOWNLOAD THESE FREE RESOURCES:</h6>
      <a href="https://www.idealist.org/en/careers/diversity-equity-inclusion-committee" target="_blank" rel="noreferrer noopener" className="is-block is-underline has-text-weight-bold">Tips for Starting a <span className="has-tooltip-arrow" data-tooltip="Diversity, Equity, Inclusion">DEI</span> Committee</a>
      <a href={PDF_Coaching} target="_blank" rel="noreferrer noopener" className="is-block is-underline has-text-weight-bold">Common Coaching Questions</a>
      <a href={PDF_Intro} target="_blank" rel="noreferrer noopener" className="is-block is-underline has-text-weight-bold">BHB Services</a>
    </>
  );
};

export { ItemCoaching as default };
