import React, { useContext } from 'react';
import ItemsContext from '../context/items-context'

const ItemIntro = ({ classes, setShow, setCurrent, setCurrentInfo }) => {

  const { items, itemsDispatch } = useContext(ItemsContext);
  const [list, setList] = React.useState(classes);

  const handleAdd = (name, info) => {
    // add item to list
    itemsDispatch({ type: 'ADD_ITEM', name, info });
    // selected state
    const newList = list.map((item) => {
      if (item.name === name) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });
    setList(newList);
    // open modal
    setShow(true);
    setCurrent(name);
    setCurrentInfo(info);
  };

  const handleRemove = (name) => {
    // remove item from list
    itemsDispatch({ type: 'REMOVE_ITEM', name });
    // selected state
    const newList = list.map((item) => {
      if (item.name === name) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });
    setList(newList);
    setCurrent(null);
    setCurrentInfo(null);
  };

  return (
    <>
      <h1>
        Interested, but not sure Babes Helpin’ Babes is right for your company? Contact us today for a <mark>free 30-minute introduction call</mark>
      </h1>
      {list.map(item => (
        <>
          {item.selected ?
            <button
              key={item.id}
              value={item.name}
              className="btn--gray my-4 is-italic"
              onClick={() => handleRemove(item.name)}
            >
              SELECTED<span className="tag is-delete ml-2"></span>
            </button> :
            <button
              key={item.id}
              value={item.name}
              className="btn--white my-4"
              onClick={() => handleAdd(item.name, item.info)}
            >
              SELECT
              </button>
          }
        </>
      ))}
    </>
  );
};

export { ItemIntro as default };
