import React, { useContext } from 'react'
import ItemsContext from '../context/items-context'
import { v4 as uuidv4 } from 'uuid'

const ItemIndividual = ({ classes, setShow, setCurrent, setCurrentInfo }) => {

  const { items, itemsDispatch } = useContext(ItemsContext);
  const [list, setList] = React.useState(classes);

  const handleAdd = (name, info) => {
    // add item to list
    itemsDispatch({ type: 'ADD_ITEM', name, info });
    // selected state
    const newList = list.map((item) => {
      if (item.name === name) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });
    setList(newList);
    // open modal
    setShow(true);
    setCurrent(name);
    setCurrentInfo(info);
  };

  const handleRemove = (name) => {
    // remove item from list
    itemsDispatch({ type: 'REMOVE_ITEM', name });
    // selected state
    const newListRemove = list.map((item) => {
      console.log(item.name + name)
      if (item.name === name) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });
    setList(newListRemove);
    setCurrent(null);
    setCurrentInfo(null);
  };

  return (
    <>
      <ul>
        {list.map(item => (
          <li key={uuidv4()} className="mb-5">
            <h6 className="has-letter-spacing" dangerouslySetInnerHTML={{ __html: item.name }} />
            {item.selected ?
              <button
                value={item.name}
                className="button link normal is-italic"
                onClick={() => handleRemove(item.name)}
              >
                SELECTED <span className="tag is-delete is-danger is-light ml-2"></span>
              </button> :
              <button
                value={item.name}
                className="button link"
                onClick={() => handleAdd(item.name, item.info)}
              >
                SELECT
              </button>
            }
          </li>

        ))}
      </ul>


    </>
  );
};

export { ItemIndividual as default };
