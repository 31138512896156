import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import allClasses from '../components/ClassLists/allClasses'
import ItemIndividual from '../components/ClassLists/ClassesIndividual'
import ItemMonthly from '../components/ClassLists/ClassesMonthly'
import ItemCoaching from '../components/ClassLists/ClassesCoaching'
import ItemConsulting from '../components/ClassLists/ClassesConsulting'
import ItemIntro from '../components/ClassLists/ClassesIntro'
import Modal from '../components/ClassLists/Modal'
import 'smoothscroll-anchor-polyfill'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"


export const GetStartedPageTemplate = ({
  title,
  intro,
  heading,
  description,
  image,
  onemonthclass,
  heading2,
  description2,
  image2,
  heading3,
  description3,
  image3,
}) => {



  const individualClassList = allClasses.filter(cat => cat.category === "individual");
  const monthlyClassList = allClasses.filter(cat => cat.category === "monthly");
  const coachingClassList = allClasses.filter(cat => cat.category === "coaching");
  const consultingClassList = allClasses.filter(cat => cat.category === "consulting");
  const introClassList = allClasses.filter(cat => cat.category === "intro");

  // show modal dialog box
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentinfo, setCurrentInfo] = useState(null);

  return (
    <div>
      <section className="py-5">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-11">
              <div className="section has-text-centered">
                <h2
                  className="has-text-weight-bold is-size-1 pb-6">
                  {title}
                </h2>
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-primary" id="section-1">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="section has-text-centered">
                <ScrollAnimation animateIn="animate__flipInX" duration={0.5} offset={0}><h3 className="sans">{heading}</h3></ScrollAnimation>
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="columns">
          <div className="column is-half">
            <div className="section is-flex is-flex-direction-column is-justify-content-center is-align-items-start" style={{
              maxWidth: '700px',
              margin: '0 auto',
              minHeight: '500px',
              height: '100%'
            }}>
              <h1>Individual Classes</h1>
              <p>Individual classes are intended to break the ice and spark conversation. When you start with the foundational courses, your company is already on its way toward meaningful change. </p>

              <p className="mb-0">Pricing is based on company size and starts at $2,000. Classes are 2-hours and can be held anytime during business hours.</p>

            </div>
          </div>
          <div className="column is-half">
            <div className="section" style={{
              maxWidth: '700px',
              margin: '0 auto'
            }}>
              <ItemIndividual
                classes={individualClassList}
                setShow={setShow}
                setCurrent={setCurrent}
                setCurrentInfo={setCurrentInfo}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-primary">
        <div className="columns">
          <div className="column is-half has-text-white">
            <div className="section is-flex is-flex-direction-column is-justify-content-center is-align-items-start" style={{
              maxWidth: '700px',
              margin: '0 auto',
              minHeight: '600px',
              height: '100%'
            }}>
              <h1 className="mt-3">{onemonthclass.heading}</h1>
              <p dangerouslySetInnerHTML={{ __html: onemonthclass.description }} />
            </div>
          </div>
          <div className="column has-background-white-ter is-half p-0">
            <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} style={{ height: '100%' }}>
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', minHeight: '400px'
                }}
              ></div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
      {/*<section>*/}
      {/*  <div className="container">*/}
      {/*    <div className="columns is-centered">*/}
      {/*      <div className="column is-11">*/}
      {/*        <h4 className="sans has-text-centered is-uppercase mt-6">We currently offer three tracks for our Dedicated Class Series:</h4>*/}
      {/*        <div className="columns">*/}
      {/*          <ItemMonthly*/}
      {/*            classes={monthlyClassList}*/}
      {/*            setShow={setShow}*/}
      {/*            setCurrent={setCurrent}*/}
      {/*            setCurrentInfo={setCurrentInfo}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className="has-background-warning" id="section-2">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-11">
              <div className="section has-text-centered">
                <ScrollAnimation animateIn="animate__flipInX" duration={0.5} offset={0}><h3 className="sans has-text-white" dangerouslySetInnerHTML={{ __html: heading2 }} /></ScrollAnimation>
                <div className="has-text-white" dangerouslySetInnerHTML={{ __html: description2 }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="columns">
          <div className="column is-half">
            <div className="section" style={{
              maxWidth: '700px',
              margin: '0 auto'
            }}>
              <ItemCoaching
                classes={coachingClassList}
                setShow={setShow}
                setCurrent={setCurrent}
                setCurrentInfo={setCurrentInfo}
              />
            </div>
          </div>
          <div className="column is-half p-0 has-background-info">
            <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} style={{ height: '100%' }}>
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${!!image2.childImageSharp ? image2.childImageSharp.fluid.src : image2
                    })`, backgroundSize: 'cover', backgroundPosition: 'center bottom', height: '100%', minHeight: '300px'
                }}
              ></div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
      <section className="has-background-info" id="section-3">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-11">
              <div className="section has-text-centered">
                <ScrollAnimation animateIn="animate__flipInX" duration={0.5} offset={0}><h3 className="sans has-text-white">{heading3}</h3></ScrollAnimation>
                <div className="has-text-white" dangerouslySetInnerHTML={{ __html: description3 }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="columns">
          <div className="column is-half p-0 has-background-white-ter">
            <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} style={{ height: '100%' }}>
              <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url(${!!image3.childImageSharp ? image3.childImageSharp.fluid.src : image3
                    })`, backgroundSize: 'cover', backgroundPosition: 'center 80%', height: '100%', minHeight: '400px'
                }}
              ></div>
            </ScrollAnimation>
          </div>
          <div className="column is-half">
            <div className="section is-flex is-flex-direction-column is-justify-content-center is-align-items-start" style={{
              maxWidth: '700px',
              margin: '0 auto',
              height: '100%'
            }}>
              <ItemConsulting
                classes={consultingClassList}
                setShow={setShow}
                setCurrent={setCurrent}
                setCurrentInfo={setCurrentInfo}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="has-background-primary py-5">
        <div className="container py-5">
          <div className="columns is-centered py-5">
            <div className="column is-11">
              <ScrollAnimation animateIn="animate__flipInX">
                <div className="section has-text-centered">
                  <ItemIntro
                    classes={introClassList}
                    setShow={setShow}
                    setCurrent={setCurrent}
                    setCurrentInfo={setCurrentInfo}
                  />
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        setShow={setShow}
        current={current}
        currentinfo={currentinfo}
      />
    </div>
  )
}


GetStartedPageTemplate.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onemonthclass: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  heading2: PropTypes.string,
  description2: PropTypes.string,
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading3: PropTypes.string,
  description3: PropTypes.string,
  image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const GetStartedPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <GetStartedPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        onemonthclass={frontmatter.onemonthclass}
        heading2={frontmatter.heading2}
        description2={frontmatter.description2}
        image2={frontmatter.image2}
        heading3={frontmatter.heading3}
        description3={frontmatter.description3}
        image3={frontmatter.image3}
      />
    </Layout>
  )
}

GetStartedPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default GetStartedPage

export const GetStartedPageQuery = graphql`
  query GetStartedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        intro {
          blurbs {
            headline
            text
            caption
          }
        }
        heading
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        onemonthclass {
          heading
          description
        }
        heading2
        description2
        image2 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading3
        description3
        image3 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
